import React from "react";
import LoadingBar from "react-top-loading-bar";

import {
  createRootRouteWithContext,
  Outlet,
  useRouter,
} from "@tanstack/react-router";
import { QueryClient } from "@tanstack/react-query";

export interface IRouterContext {
  auth: IAuthContext;
  queryClient: QueryClient;
}

function RouteComponent() {
  const [progress, setProgress] = React.useState(0);
  const router = useRouter();

  React.useEffect(() => {
    const unsubscribe1 = router.subscribe("onBeforeLoad", () =>
      setProgress(30)
    );
    const unsubscribe2 = router.subscribe("onResolved", () => setProgress(100));

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, [router]);

  return (
    <React.Fragment>
      <LoadingBar
        color="#88D63B"
        progress={progress}
        waitingTime={400}
        onLoaderFinished={() => {
          setProgress(0);
        }}
      />
      <Outlet />
    </React.Fragment>
  );
}

export const Route = createRootRouteWithContext<IRouterContext>()({
  component: RouteComponent,
});
