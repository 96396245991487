import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import React from "react";

type Props = {
  onSubmit?: () => void;
  onCancel?: () => void;
};

export function CreateLicenseSchool(props: Props) {
  const [level, setLevel] = React.useState(1);

  return (
    <Card>
      <CardContent className="grid grid-cols-4 gap-4 px-6 mt-6">
        <h3 className="col-span-4 text-lg font-semibold">Dados da escola</h3>
        <div className="col-span-4 flex items-center space-x-4">
          <Button
            size={"sm"}
            variant={level === 1 ? "default" : "secondary"}
            onClick={() => setLevel(1)}
          >
            Ensino Médio
          </Button>
          <Button
            size={"sm"}
            variant={level === 2 ? "default" : "secondary"}
            onClick={() => setLevel(2)}
          >
            Ensino Superior
          </Button>
        </div>
        <div className="space-y-1 col-span-4">
          <Label>Escola ou Instituição de Ensino</Label>
          <Input placeholder="Ecreva o seu nome aqui..." />
        </div>
        <div className="space-y-1 col-span-2">
          <Label>Localização no Bairro</Label>
          <Input placeholder="Escreva o nome do pai..." />
        </div>
        <div className="space-y-1 col-span-2">
          <Label>Municipio</Label>
          <Input placeholder="Escreva o nome da mãe..." />
        </div>
        <div className="space-y-1 col-span-2">
          <Label>Província</Label>
          <Select>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Selecione o genêro..." />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Genêro</SelectLabel>
                <SelectItem value="1">Maculino</SelectItem>
                <SelectItem value="2">Feminino</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="space-y-1 col-span-2">
          <Label>País</Label>
          <Select>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Selecione o genêro..." />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Genêro</SelectLabel>
                <SelectItem value="1">Maculino</SelectItem>
                <SelectItem value="2">Feminino</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-1 col-span-2">
          <Label>Data de início</Label>
          <Select>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Selecione o estado..." />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Estado cívil</SelectLabel>
                <SelectItem value="single">Solteiro(a)</SelectItem>
                <SelectItem value="married">Casado(a)</SelectItem>
                <SelectItem value="widowed">Divorciado(a)</SelectItem>
                <SelectItem value="grapes">Viúvo(a)</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-1 col-span-2">
          <Label>Data de conclusão</Label>
          <Select>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Selecione o genêro..." />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Genêro</SelectLabel>
                <SelectItem value="1">Maculino</SelectItem>
                <SelectItem value="2">Feminino</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-1 col-span-4 flex justify-center flex-col items-center">
          <div className="max-w-[440px] w-full mt-6">
            <div className="w-full flex justify-center flex-col items-center">
              <h3 className="text-sm mb-4">Assinatura</h3>
              <Separator className="bg-gray-900 max-w-[calc(100%_-_7rem)]" />
              <div className="text-sm mt-2 text-center">
                Conselho provincial de Luanda aos, ____ de __________ de{" "}
                {new Date().getFullYear()}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
      <CardFooter className="justify-between">
        <Button
          className="py-6 rounded-lg"
          variant={"outline"}
          onClick={props.onCancel}
        >
          Voltar
        </Button>

        <Button className="py-6 rounded-lg" onClick={props.onSubmit}>
          Aprovar
        </Button>
      </CardFooter>
    </Card>
  );
}
