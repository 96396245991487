const formatMoney = (value: number | string, free = true) => {
  value = Number(value);

  if (value === 0 && free) return "Grátis";

  return value
    .toLocaleString("pt-AO", { style: "currency", currency: "AOA" })
    .replace("AOA", "KZ");
};

const formatMoneyGlobal = (
  value: number | string,
  options?: Intl.NumberFormatOptions & {
    locales?: Intl.LocalesArgument;
    free?: boolean;
  }
) => {
  const { locales, free = true, ..._options } = options ?? {};
  value = Number(value);

  if (value === 0 && free) return "Grátis";

  return value.toLocaleString(locales, _options);
};

const formatMoneyEUR = (value: number | string, free = true) => {
  return formatMoneyGlobal(value, {
    locales: "en-DE",
    style: "currency",
    currency: "EUR",
    free,
  });
};

export const formatEUR = (value: number | string, free = true) => {
  return formatMoneyGlobal(value, {
    locales: "en-DE",
    style: "currency",
    currency: "EUR",
    free,
  });
};
export const formatEUR1 = (value: number | string, free = true) => {
  if (value === 0 && free) return "Grátis";

  return value
    .toLocaleString("pt-AO", { style: "currency", currency: "AOA" })
    .replace("AOA", "€")
    .replace(",00", "");
};

export { formatMoneyGlobal, formatMoneyEUR, formatMoney };
