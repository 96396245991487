import { useAuth } from "@/hooks/useAuth";
import { Link } from "@tanstack/react-router";
import { Notification } from "iconsax-react";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { LogOut, Settings, User } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";

export function Header() {
  const auth = useAuth();

  return (
    <header className="flex justify-between items-center h-16 mb-4">
      <Link to="/" className="flex justify-start items-center space-x-2">
        <img src="/copenfl.png" alt="COPENFL" className="h-12 object-cover" />
        <h1 className="russo-one text-3xl">COPENFL</h1>
      </Link>

      <div className="justify-end flex items-center space-x-6">
        <button className="h-[3.25rem] w-[3.25rem] border-2 rounded-full border-zinc-100 duration-75 ease-linear hover:bg-zinc-200 justify-center items-center flex">
          <Notification className="w-6 h-6 text-gray-500" />
        </button>

        <DropdownMenu>
          <DropdownMenuTrigger>
            <Avatar className="h-[3.25rem] w-[3.25rem]">
              <AvatarImage src={auth.user?.Photo} />
              <AvatarFallback>
                {(
                  auth.user?.FirstName.charAt(0)! +
                  auth.user?.LastName.charAt(0)!
                ).toUpperCase()}
              </AvatarFallback>
            </Avatar>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56 mr-14">
            <DropdownMenuLabel>
              <h3>{auth.user?.FirstName + " " + auth.user?.LastName}</h3>
              <span className="font-normal text-sm text-zinc-500">
                {auth.user?.Phone ?? auth.user?.Email}
              </span>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem>
                <User className="mr-2 h-4 w-4" />
                <span>Perfil</span>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Settings className="mr-2 h-4 w-4" />
                <span>Configurações</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={auth.logout}>
              <LogOut className="mr-2 h-4 w-4" />
              <span>Terminar sessão</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
}
