import RootLayout from "@/layouts/root";

import { createFileRoute, redirect } from "@tanstack/react-router";

function HomePage() {
  return (
    <RootLayout>
      <div className="space-y-4">
        <div className="bg-white px-3.5 py-4 rounded-lg w-full"></div>
      </div>
    </RootLayout>
  );
}

export const Route = createFileRoute("/")({
  component: HomePage,
  loader() {
    document.title = "Dashboard";
  },
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({ to: "/auth/signin" });
    }
  },
});
