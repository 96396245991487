import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { buttonVariants } from "@/constants/button-variants";
import { cn } from "@/lib/utils";
import { FileUp, Upload } from "lucide-react";

type Props = {
  onSubmit?: () => void;
  onCancel?: () => void;
};

export function CreateLicenseDemographicData(props: Props) {
  return (
    <Card>
      <CardContent className="grid grid-cols-4 gap-4 px-6 mt-6">
        <div className="col-span-4">
          <h3 className="text-lg font-semibold">Documentos</h3>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4 mb-6">
            {[
              "Cópia Autenticada do Certificado",
              "Cópia Autenticada do Bilhete de identidade/Passaporte",
              "Licença de Aprendizaado",
              "Licença de Aprendizaado",
            ].map((item, index) => (
              <div
                key={index}
                className="space-y-3 flex flex-col justify-start items-center"
              >
                <div className="flex justify-center items-center bg-gray-200 w-36 h-40 rounded-lg">
                  <FileUp className="w-10 h-10 text-gray-500" />
                </div>

                <div className="w-36 grid grid-cols-1 gap-1">
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <h3 className="text-sm font-semibold line-clamp-2">
                        {item}
                      </h3>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{item}</p>
                    </TooltipContent>
                  </Tooltip>

                  <p className="text-xs text-gray-500">
                    Carregue aqui a sua foto.{" "}
                    <span className="text-gray-900">
                      Orientações importantes:
                    </span>
                    800x800 pixels or 1:1 Ratio. Formato suportado:{" "}
                    <span className="text-gray-900">.jpg, .jpeg, ou .png</span>
                  </p>
                  <label
                    className={cn(
                      buttonVariants({
                        variant: "outline-app",
                        className: "w-fu px-4 cursor-pointer mt-2",
                      })
                    )}
                    htmlFor="image-profile"
                  >
                    <div className="flex justify-center items-center space-x-3 px-4">
                      <span className="text-xs">Carregar PDF</span>

                      <Upload className="h-4 w-4" />
                    </div>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>

        <h3 className="col-span-4 text-lg font-semibold">
          Endereço atualizado
        </h3>
        <div className="space-y-1 col-span-4">
          <Label>Rua</Label>
          <Input placeholder="Ecreva o seu nome aqui..." />
        </div>
        <div className="space-y-1 col-span-2">
          <Label>Prédio</Label>
          <Input placeholder="Escreva o nome do pai..." />
        </div>
        <div className="space-y-1 col-span-2">
          <Label>Apto Nº / Casa Nº</Label>
          <Input placeholder="Escreva o nome da mãe..." />
        </div>
        <div className="space-y-1 col-span-2">
          <Label>Bairro</Label>
          <Input placeholder="Escreva o nome da mãe..." />
        </div>
        <div className="space-y-1 col-span-2">
          <Label>Comuna / Distrito</Label>
          <Input placeholder="Escreva o nome da mãe..." />
        </div>

        <div className="space-y-1 col-span-2">
          <Label>Munícipio</Label>
          <Select>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Selecione o estado..." />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Estado cívil</SelectLabel>
                <SelectItem value="single">Solteiro(a)</SelectItem>
                <SelectItem value="married">Casado(a)</SelectItem>
                <SelectItem value="widowed">Divorciado(a)</SelectItem>
                <SelectItem value="grapes">Viúvo(a)</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-1 col-span-2">
          <Label>Provínciaa</Label>
          <Select>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Selecione o genêro..." />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Genêro</SelectLabel>
                <SelectItem value="1">Maculino</SelectItem>
                <SelectItem value="2">Feminino</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-1 col-span-4">
          <Label>E-mail</Label>
          <Input placeholder="Escreva o nome da mãe..." />
        </div>

        <div className="space-y-1 col-span-2">
          <Label>Telefone 1</Label>
          <Input placeholder="Escreva o nome da mãe..." />
        </div>

        <div className="space-y-1 col-span-2">
          <Label>Telefone 2</Label>
          <Input placeholder="Escreva o nome da mãe..." />
        </div>
      </CardContent>
      <CardFooter className="justify-between">
        <Button
          className="py-6 rounded-lg"
          variant={"outline"}
          onClick={props.onCancel}
        >
          Voltar
        </Button>

        <Button className="py-6 rounded-lg" onClick={props.onSubmit}>
          Guardar e seguinte
        </Button>
      </CardFooter>
    </Card>
  );
}
