/* eslint-disable @typescript-eslint/no-unused-vars */

import React from "react";

import { toast } from "sonner";
import { SESSION_KEY } from "@/constants/keys";
import { useCookies } from "react-cookie";
import { redirect } from "@tanstack/react-router";

export const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: false,
  logout: async () => {},
  user: null,
  syncUser: async () => false,
  token: null,
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [cookies, setCookie, removeCookie] = useCookies([SESSION_KEY]);
  const [user, setUser] = React.useState<IUser | null>(null);

  const isAuthenticated = !!cookies["token.auth.user"];

  const logout = React.useCallback(async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      // verificar se o coockie existe
      if (cookies["token.auth.user"])
        myHeaders.append(
          "Authorization",
          "Bearer " + cookies["token.auth.user"]
        );

      const requestOptions: RequestInit = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      await fetch("/api/v1/auth/signout", requestOptions);

      setUser(null);
      removeCookie("token.auth.user");
      window.location.href = "/auth/signin";
    } catch (error: any) {
      toast(error.message);
    }
  }, [cookies, removeCookie]);

  const syncUser = React.useCallback(async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      // verificar se o coockie existe
      if (cookies["token.auth.user"])
        myHeaders.append(
          "Authorization",
          "Bearer " + cookies["token.auth.user"]
        );

      const requestOptions: RequestInit = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch("/api/v1/user/me", requestOptions);

      if (response.status === 200) {
        const user: IUser = await response.json();
        console.log(user);

        // atualizar os dados do user
        setUser(user);
        return user;
      }
    } catch (error: any) {
      toast(error.message);
    }
    return false;
  }, [cookies]);

  React.useEffect(() => {
    if (cookies["token.auth.user"]) syncUser();
  }, [cookies, syncUser]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        logout,
        token: cookies["token.auth.user"],
        syncUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
