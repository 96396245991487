import React from "react";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { CreateLicenseDemographicData } from "./demographic-data";
import { ScrollArea } from "@/components/ui/scroll-area";
import { CreateLicenseInformation } from "./information";
import { StackIcon } from "@radix-ui/react-icons";
import { ClipboardText } from "iconsax-react";
import { GraduationCap } from "lucide-react";
import { CreateLicenseSchool } from "./school";

type TapTypes = "information" | "demographic-data" | "school";
type Props = {
  asChild?: boolean;
  children: React.ReactNode;
  hide?: boolean;
  disabled?: boolean;
};

export function CreateLicense(props: Props) {
  const { children, asChild, hide = false, disabled = false } = props;

  const [tab, setTab] = React.useState<TapTypes>("information");

  return (
    <>
      {!hide && (
        <Dialog>
          <DialogTrigger disabled={disabled} asChild={asChild}>
            {children}
          </DialogTrigger>
          <DialogContent className="max-w-5xl px-0">
            <Tabs
              value={tab}
              defaultValue={tab}
              // @ts-ignore
              onValueChange={setTab}
              className="w-full"
            >
              <TabsList className="px-6">
                <TabsTrigger
                  value="information"
                  className="group border-b-2 border-gray-200 data-[state=active]:border-app rounded-none !shadow-none"
                >
                  <StackIcon className="w-4 h-4 text-gray-500 group-data-[state=active]:text-foreground mr-1" />
                  Informações
                </TabsTrigger>
                <TabsTrigger
                  value="demographic-data"
                  className="group border-b-2 border-gray-200 data-[state=active]:border-app rounded-none !shadow-none"
                >
                  <ClipboardText className="w-4 h-4 text-gray-500 group-data-[state=active]:text-foreground mr-1" />
                  Documentos e dados demográficos
                </TabsTrigger>
                <TabsTrigger
                  value="school"
                  className="group border-b-2 border-gray-200 data-[state=active]:border-app rounded-none !shadow-none"
                >
                  <GraduationCap className="w-4 h-4 text-gray-500 group-data-[state=active]:text-foreground mr-1" />
                  Dados da escola
                </TabsTrigger>
              </TabsList>
              <TabsContent value="information">
                <ScrollArea className="w-full h-[calc(100vh_-_10rem)] max-h-[700px]">
                  <CreateLicenseInformation
                    onSubmit={() => setTab("demographic-data")}
                  />
                </ScrollArea>
              </TabsContent>
              <TabsContent value="demographic-data">
                <ScrollArea className="w-full h-[calc(100vh_-_10rem)] max-h-[700px]">
                  <CreateLicenseDemographicData
                    onSubmit={() => setTab("school")}
                    onCancel={() => setTab("information")}
                  />
                </ScrollArea>
              </TabsContent>
              <TabsContent value="school">
                <ScrollArea className="w-full h-[calc(100vh_-_10rem)] max-h-[700px]">
                  <CreateLicenseSchool
                    onSubmit={() => {}}
                    onCancel={() => setTab("demographic-data")}
                  />
                </ScrollArea>
              </TabsContent>
            </Tabs>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
