import RootLayout from "@/layouts/root";
import { createFileRoute, redirect } from "@tanstack/react-router";

function InternalAreaPage() {
  return (
    <RootLayout>
      <div>Hello /internal-area/</div>
    </RootLayout>
  );
}

export const Route = createFileRoute("/internal-area/")({
  component: InternalAreaPage,
  loader() {
    document.title = "Área Interna - COPENFL";
  },
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({ to: "/auth/signin" });
    }
  },
});
