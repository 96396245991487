import RootLayout from "@/layouts/root";
import { createFileRoute, redirect } from "@tanstack/react-router";

function ComputingPage() {
  return (
    <RootLayout>
      <div>Hello /computing/!</div>
    </RootLayout>
  );
}

export const Route = createFileRoute("/computing/")({
  component: ComputingPage,
  loader() {
    document.title = "Informática - COPENFL";
  },
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({ to: "/auth/signin" });
    }
  },
});
