import * as React from "react";

import { cn } from "@/lib/utils";
import { Eye, EyeSlash } from "iconsax-react";
import { Search } from "react-iconly";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  search?: boolean;
  onSearch?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, search = false, ...props }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const togglePasswordVisibility = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.preventDefault();
      setShowPassword(!showPassword);
    };

    return (
      <div className="relative">
        {props.icon !== undefined && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            {props.icon}
          </div>
        )}
        <input
          type={
            search
              ? "search"
              : type === "password"
                ? showPassword
                  ? "text"
                  : "password"
                : type
          }
          className={cn(
            "flex h-9 w-full rounded-lg border border-input bg-transparent px-3 py-1 text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
            className,
            props.icon !== undefined && "pl-12",
            type === "password" && "pr-12"
          )}
          ref={ref}
          {...props}
        />
        {search ? (
          <button
            onClick={props.onSearch}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <Search size="small" primaryColor="#8E92BC" />
          </button>
        ) : (
          type === "password" && (
            <button
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 flex items-center pr-3"
            >
              {showPassword ? (
                <EyeSlash size="20" color="#020617" />
              ) : (
                <Eye size="20" color="#020617" />
              )}
            </button>
          )
        )}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
