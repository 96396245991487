import React from "react";

import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "@/lib/utils";
import { CalendarIcon } from "lucide-react";
import moment from "moment";
import { Calendar } from "../ui/calendar";
import { formatMoney } from "../../lib/format-money";

type Props = {
  asChild?: boolean;
  children: React.ReactNode;
};

const formSchema = z.object({
  category: z.string(),
  type: z.enum(["salary-quota", "fixed-quota"]),
  baseSalary: z.number().optional(),
  fixedValue: z.number().optional(),
  monthValue: z.number().optional(),
  lastPaymentDate: z.date(),
  currentPaymentDate: z.date(),
});

const calculate = (
  baseSalary: number,
  monthValue: number,
  lastPaymentDate: Date,
  currentPaymentDate: Date
) => {
  const daysInMonth = moment(currentPaymentDate).daysInMonth();
  const daysPassed = moment(currentPaymentDate).diff(
    lastPaymentDate,
    "days",
    true
  );
  const debt =
    (baseSalary * daysPassed) / daysInMonth + monthValue * daysPassed;

  return {
    debt,
  };
};

const calculateFixed = (
  fixedValue: number,
  lastPaymentDate: Date,
  currentPaymentDate: Date
) => {
  const daysPassed = moment(currentPaymentDate).diff(
    lastPaymentDate,
    "days",
    true
  );
  const debt = fixedValue * daysPassed;

  return {
    debt,
  };
};

export default function Calculator({ children, asChild }: Props) {
  const [debt, setDebt] = React.useState(0);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: "salary-quota",
      category: "0",
      baseSalary: 0,
      fixedValue: 0,
      monthValue: 0,
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    const {
      baseSalary,
      fixedValue,
      monthValue,
      lastPaymentDate,
      currentPaymentDate,
    } = values;

    if (values.type === "salary-quota") {
      const result = calculate(
        baseSalary!,
        monthValue!,
        lastPaymentDate,
        currentPaymentDate
      );
      setDebt(result.debt);
    } else {
      const result = calculateFixed(
        fixedValue!,
        lastPaymentDate,
        currentPaymentDate
      );
      setDebt(result.debt);
    }
  }

  return (
    <Dialog>
      <DialogTrigger asChild={asChild}>{children}</DialogTrigger>
      <DialogContent className="max-w-[26.75rem] w-full px-0">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle className="px-6">Calculadora</DialogTitle>
            </DialogHeader>

            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="px-6 grid grid-cols-2 gap-5 items-center justify-between my-4">
                      <Button
                        variant={
                          field.value === "salary-quota" ? "default" : "outline"
                        }
                        className="px-5 text-base rounded-2xl font-semibold py-6"
                        onClick={() => field.onChange("salary-quota")}
                        type="button"
                      >
                        Quota salarial
                      </Button>
                      <Button
                        variant={
                          field.value === "fixed-quota" ? "default" : "outline"
                        }
                        className="px-5 text-base rounded-2xl font-semibold py-6"
                        onClick={() => field.onChange("fixed-quota")}
                      >
                        Quotas Fixas
                      </Button>
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />

            <div className="px-6 space-y-4">
              <FormField
                control={form.control}
                name="category"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-normal">
                      Categoria
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Selecione uma categoria" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="1">Categoria 1</SelectItem>
                        <SelectItem value="2">Categoria 2</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {form.watch("type") === "salary-quota" ? (
                <div className="grid grid-cols-2 gap-5">
                  <FormField
                    control={form.control}
                    name="baseSalary"
                    render={({ field: { onChange, ...field } }) => (
                      <FormItem>
                        <FormLabel className="text-sm font-normal">
                          Salário base
                        </FormLabel>
                        <FormControl>
                          <Input
                            placeholder="0000,00Kz"
                            type="number"
                            onChange={(e) => onChange(Number(e.target.value))}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="monthValue"
                    render={({ field: { onChange, ...field } }) => (
                      <FormItem>
                        <FormLabel className="text-sm font-normal">
                          Valor mensal
                        </FormLabel>
                        <FormControl>
                          <Input
                            placeholder="0000,00Kz"
                            type="number"
                            onChange={(e) => onChange(Number(e.target.value))}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              ) : (
                <FormField
                  control={form.control}
                  name="fixedValue"
                  render={({ field: { onChange, ...field } }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-normal">
                        Valor fixo
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder="0000,00Kz"
                          type="number"
                          onChange={(e) => onChange(Number(e.target.value))}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <div className="grid grid-cols-2 gap-5">
                <FormField
                  control={form.control}
                  name="lastPaymentDate"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-normal line-clamp-1">
                        Data último pagamento
                      </FormLabel>

                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={"outline"}
                              className={cn(
                                "w-full justify-start text-left font-normal overflow-hidden",
                                !field.value && "text-muted-foreground"
                              )}
                            >
                              {field.value ? (
                                moment(field.value).format(
                                  "DD [de] MMMM [de] YYYY"
                                )
                              ) : (
                                <span>01 de Janeiro de 2034</span>
                              )}
                              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            selected={field.value}
                            onSelect={field.onChange}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="currentPaymentDate"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-normal line-clamp-1">
                        Data pagamento actual
                      </FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={"outline"}
                              className={cn(
                                "w-full justify-start text-left font-normal overflow-hidden",
                                !field.value && "text-muted-foreground"
                              )}
                            >
                              {field.value ? (
                                moment(field.value).format(
                                  "DD [de] MMMM [de] YYYY"
                                )
                              ) : (
                                <span>01 de Janeiro de 2034</span>
                              )}
                              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            selected={field.value}
                            onSelect={field.onChange}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="space-y-2 mt-5">
                <div className="space-y-1">
                  <div className="flex justify-between items-center">
                    <p className="text-sm font-bold text-gray-500">Divída</p>
                    <p className="text-base font-bold text-gray-500">
                      {formatMoney(debt, false)}
                    </p>
                  </div>
                </div>
                <Separator />
              </div>
            </div>

            <DialogFooter className="grid grid-cols-2 gap-7 px-6 mt-5">
              <DialogClose asChild>
                <Button
                  className="py-6 rounded-lg"
                  type="button"
                  variant={"outline"}
                >
                  Cancelar
                </Button>
              </DialogClose>
              <Button className="py-6 rounded-lg">Calcular</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
