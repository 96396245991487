import { cn } from "@/lib/utils";
import { ButtonSubmenuProps } from "./types";
import { ChevronDown } from "react-iconly";

type Props = React.HTMLAttributes<HTMLButtonElement> & ButtonSubmenuProps;

export function ButtonSubmenu(props: Props) {
  const { current, isOpen, counter, Icon, haveChildren, text, ...rest } = props;

  return (
    <button
      className={cn(
        "hover:bg-gray-500/10 flex items-center w-full group rounded-xl text-zinc-700 duration-300 ease-in-out relative",
        "py-3 text-sm gap-2.5 px-3",
        current && "bg-primary-design text-white py-4 hover:bg-primary-design",
        (current || isOpen) && "mb-2",
      )}
      {...rest}
    >
      <Icon
        size={20}
        stroke="bold"
        set={current ? "bulk" : "light"}
        primaryColor={current ? "#ffffff" : "#53545C"}
      />{" "}
      {text}
      {haveChildren !== undefined && (
        <ChevronDown
          // @ts-ignore
          className={cn(
            "text-zinc-700 duration-300 ease-linear h-3 w-3 absolute right-5 top-1/2 -translate-y-1/2",
            isOpen ? "rotate-180" : "rotate-0",
            current && "text-white",
          )}
          size={16}
        />
      )}
      {counter ? (
        <div
          className={cn(
            "duration-300 ease-in-out h-6 w-6 rounded-full flex items-center justify-center text-[10px] font-semibold",
            current
              ? "bg-white text-primary-design"
              : "bg-primary-design text-white",

            current && "absolute top-1/2 -translate-y-1/2 right-5",
          )}
        >
          <span className="text-center">{counter}</span>
        </div>
      ) : null}
    </button>
  );
}
