import RootLayout from "@/layouts/root";
import React from "react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { Calendar, TimeCircle } from "react-iconly";
import { CreateLicense } from "@/components/utils/create-license";

type TypeStatus = "all" | "pending" | "verified" | "returned";

function ExternalAreaLicensePage() {
  const [status, setStaus] = React.useState<TypeStatus>("all");

  return (
    <RootLayout>
      <div className="space-y-4">
        <div className="w-full flex justify-between items-center">
          <div className="max-w-[28rem] w-full">
            <Input search placeholder="Procurar" className="py-5 px-5 w-full" />
          </div>
        </div>

        <div className="w-full flex justify-start space-x-4 items-center">
          <h1 className="text-2xl font-semibold">Licenças</h1>

          <div className="flex space-x-3 items-center">
            <Button
              size={"sm"}
              onClick={() => setStaus("all")}
              variant={status === "all" ? "default" : "secondary"}
            >
              Todos
            </Button>
            <Button
              size={"sm"}
              variant={status === "pending" ? "default" : "secondary"}
              onClick={() => setStaus("pending")}
            >
              Pendentes
            </Button>
            <Button
              size={"sm"}
              variant={status === "verified" ? "default" : "secondary"}
              onClick={() => setStaus("verified")}
            >
              Verificados
            </Button>
            <Button
              size={"sm"}
              variant={status === "returned" ? "default" : "secondary"}
              onClick={() => setStaus("returned")}
            >
              Retornados
            </Button>
          </div>
        </div>

        <div className="w-full min-h-[30rem] grid grid-cols-4 gap-6 py-6">
          {Array(
            status === "all"
              ? 12
              : status === "pending"
                ? 6
                : status === "returned"
                  ? 4
                  : status === "verified"
                    ? 2
                    : 0
          )
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className="bg-white px-3.5 py-4 rounded-lg w-full flex justify-center items-center"
              >
                <div className="space-y-4 flex-col flex justify-center items-center w-full">
                  <div className="w-full flex justify-center items-center">
                    <img
                      src="https://avatars.githubusercontent.com/u/64491235?s=400&u=d965af81dea03ccbc6c6a32c6d4715f91080a245&v=4"
                      alt="foto-licensa"
                      className="w-32 aspect-square h-32 object-cover rounded-full"
                    />
                  </div>

                  <div className="w-full flex flex-col justify-center items-center">
                    <h3 className="text-lg font-semibold line-clamp-1">
                      João da Silva
                    </h3>

                    <h3 className="text-xs text-gray-500 line-clamp-1">
                      <span className="font-medium">Escola:</span> Santa Ana e
                      Noesa
                    </h3>
                  </div>

                  <div className="w-full flex justify-between items-center">
                    <div className="flex space-x-2 items-center">
                      <Calendar primaryColor="#000000" size={"small"} />
                      <span className="font-medium text-sm block">
                        23/02/2023
                      </span>
                    </div>
                    <div className="flex space-x-2 items-center">
                      <TimeCircle primaryColor="#000000" size={"small"} />
                      <span className="font-medium text-sm block">14:30</span>
                    </div>
                  </div>

                  <CreateLicense asChild>
                    <Button className="w-full py-5">Verificar</Button>
                  </CreateLicense>
                </div>
              </div>
            ))}
        </div>
      </div>
    </RootLayout>
  );
}

export const Route = createFileRoute("/external-area/license/")({
  component: ExternalAreaLicensePage,
  loader() {
    document.title = "Licenças - COPENFL";
  },
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({ to: "/auth/signin" });
    }
  },
});
