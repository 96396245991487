import React from "react";

import { ItemProps } from "./types";
import { ToggleSubmenu } from "./toggle-submenu";
import { Link } from "@tanstack/react-router";
import { cn } from "@/lib/utils";
import { ChevronDown } from "react-iconly";

export function Item(props: ItemProps) {
  const [isOpen, toggleOpen] = React.useState(props.current || false);

  return (
    <>
      <li className="w-full">
        {props.children !== undefined ? (
          <>
            <ToggleSubmenu
              Icon={props.Icon}
              current={props.current}
              isOpen={isOpen}
              toggleOpen={toggleOpen}
              text={props.title}
              counter={props.counter}
            >
              {props.children}
            </ToggleSubmenu>
          </>
        ) : (
          <Link
            to={props.href}
            onClick={(e) => {
              if (props.children) {
                e.preventDefault();
                toggleOpen(!isOpen);
              }
            }}
            className={cn(
              "hover:bg-gray-500/10 flex items-center group rounded-xl text-zinc-700 duration-300 ease-in-out relative",
              "py-3 text-sm gap-2.5 px-3",
              props.current &&
                "bg-primary-design text-white py-4 hover:bg-primary-design",
              props.children && (props.current || isOpen) && "mb-2"
            )}
          >
            <props.Icon
              size={20}
              set={props.current ? "bulk" : "light"}
              primaryColor={props.current ? "#ffffff" : "#53545C"}
            />{" "}
            {props.title}
            {props.children !== undefined && (
              <ChevronDown
                // @ts-ignore
                className={cn(
                  "text-zinc-700 duration-300 ease-linear h-3 w-3 absolute right-5 top-1/2 -translate-y-1/2",
                  isOpen ? "rotate-180" : "rotate-0",
                  props.current && "text-white"
                )}
                size={16}
              />
            )}
            {props.counter ? (
              <div
                className={cn(
                  "duration-300 ease-in-out h-6 w-6 rounded-full flex items-center justify-center text-[10px] font-semibold",
                  props.current
                    ? "bg-white text-primary-design"
                    : "bg-primary-design text-white",
                  props.current && "absolute top-1/2 -translate-y-1/2 right-5"
                )}
              >
                <span className="text-center">{props.counter}</span>
              </div>
            ) : null}
          </Link>
        )}
      </li>
    </>
  );
}
