import { formatMoney } from "@/lib/format-money";
import { cn } from "@/lib/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Sort } from "iconsax-react";
import moment from "moment";

export type Payment = {
  id: string;
  name: string;
  user: {
    photo: string;
    name: string;
    email: string;
  };
  amount: number;
  status: TypeStatus;
  cardNumber: number;
  paymentDate: Date;
  expirationDate: Date;
  paymentType: string;
};

export const calculatorColumns: ColumnDef<Payment>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <div className="flex justify-between space-x-2 items-center mr-2 py-4">
          <span>Usuário</span>
          <button
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-1 rounded-lg cursor-pointer"
          >
            <Sort
              size={16}
              className={cn(
                "ml-2 h-4 w-4 transition-all duration-300 ease-in-out",
                column.getIsSorted() === "asc" && "rotate-180"
              )}
            />
          </button>
        </div>
      );
    },
    cell: ({ row }) => (
      <div className="flex items-center space-x-2 py-2 min-w-[12rem]">
        <img
          alt={row.original.user.name}
          src={row.original.user.photo}
          className="w-11 h-11 object-cover rounded-lg"
        />
        <div className="space-y-1">
          <div className="text-sm font-medium line-clamp-1">
            {row.original.user.name}
          </div>
          <div className="text-xs text-[#667085] line-clamp-1">
            {row.original.user.email}
          </div>
        </div>
      </div>
    ),
  },
  {
    accessorKey: "cardNumber",
    header: "Nº de Carteira",
    cell: ({ row }) => (
      <div className="text-sm font-semibold">{row.original.cardNumber}</div>
    ),
  },
  {
    accessorKey: "paymentDate",
    header: ({ column }) => {
      return (
        <div className="flex justify-between space-x-2 items-center mr-2">
          <span>Data de pagamento</span>
          <button
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-1 rounded-lg cursor-pointer"
          >
            <Sort
              size={16}
              className={cn(
                "ml-2 h-4 w-4 transition-all duration-300 ease-in-out",
                column.getIsSorted() === "asc" && "rotate-180"
              )}
            />
          </button>
        </div>
      );
    },
    cell: ({ row }) => (
      <div className="text-[#667085] text-sm font-medium">
        {moment(row.original.paymentDate).format("DD MMM YYYY")}
      </div>
    ),
  },
  {
    accessorKey: "expirationDate",
    header: ({ column }) => {
      return (
        <div className="flex justify-between space-x-2 items-center mr-2">
          <span>Data de expiração</span>
          <button
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-1 rounded-lg cursor-pointer"
          >
            <Sort
              size={16}
              className={cn(
                "ml-2 h-4 w-4 transition-all duration-300 ease-in-out",
                column.getIsSorted() === "asc" && "rotate-180"
              )}
            />
          </button>
        </div>
      );
    },
    cell: ({ row }) => (
      <div className="text-[#667085] text-sm font-medium">
        {moment(row.original.expirationDate).format("DD MMM YYYY")}
      </div>
    ),
  },
  {
    accessorKey: "amount",
    header: "Valor da Quota",
    cell: ({ row }) => (
      <div className="text-[#667085] text-sm font-medium">
        {formatMoney(row.original.amount)}
      </div>
    ),
  },
  {
    accessorKey: "paymentType",
    header: "Tipo de Pagamento",
    cell: ({ row }) => (
      <div className="text-[#667085] text-sm font-medium">
        {row.original.paymentType}
      </div>
    ),
  },
];
