import RootLayout from "@/layouts/root";
import React from "react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { createFileRoute, redirect } from "@tanstack/react-router";
import Calculator from "@/components/calculator";
import { CalculatorDataTable } from "@/components/utils/external-area/calculator/data-table";
import {
  calculatorColumns,
  Payment,
} from "@/components/utils/external-area/calculator/columns";

function ExternalAreaCalculatorPage() {
  const [status, setStaus] = React.useState<TypeStatus>("all");

  return (
    <RootLayout>
      <div className="space-y-4">
        <div className="w-full flex justify-between items-center">
          <div className="max-w-[28rem] w-full">
            <Input search placeholder="Procurar" className="py-5 px-5 w-full" />
          </div>

          <Calculator asChild>
            <Button>Calcular</Button>
          </Calculator>
        </div>

        <div className="w-full flex justify-start space-x-4 items-center">
          <h1 className="text-2xl font-semibold">Calculadora</h1>

          <div className="flex space-x-3 items-center">
            <Button
              size={"sm"}
              onClick={() => setStaus("all")}
              variant={status === "all" ? "default" : "secondary"}
            >
              Todos
            </Button>
            <Button
              size={"sm"}
              variant={status === "pending" ? "default" : "secondary"}
              onClick={() => setStaus("pending")}
            >
              Pendentes
            </Button>
            <Button
              size={"sm"}
              variant={status === "verified" ? "default" : "secondary"}
              onClick={() => setStaus("verified")}
            >
              Verificados
            </Button>
            <Button
              size={"sm"}
              variant={status === "returned" ? "default" : "secondary"}
              onClick={() => setStaus("returned")}
            >
              Retornados
            </Button>
          </div>
        </div>

        <div className="w-full min-h-[30rem]">
          <CalculatorDataTable
            columns={calculatorColumns}
            data={
              [
                {
                  amount: 30000,
                  cardNumber: 240120443,
                  expirationDate: new Date("2026-01-01 "),
                  paymentDate: new Date("2023-02-23"),
                  status: "pending",
                  paymentType: "Anual",
                  id: "assx",
                  name: "Mingo Da Costa",
                  user: {
                    email: "mingo@costa.ao",
                    name: "Mingo Da Costa",
                    photo:
                      "https://avatars.githubusercontent.com/u/64491235?s=400&u=d965af81dea03ccbc6c6a32c6d4715f91080a245&v=4",
                  },
                },
                {
                  amount: 34000,
                  cardNumber: 240120443,
                  expirationDate: new Date("2026-01-01 "),
                  paymentDate: new Date("2023-02-23"),
                  status: "pending",
                  paymentType: "Anual",
                  id: "assx",
                  name: "Dingo Da Costa",
                  user: {
                    email: "dingo@costa.ao",
                    name: "Dingo Da Costa",
                    photo:
                      "https://avatars.githubusercontent.com/u/64491235?s=400&u=d965af81dea03ccbc6c6a32c6d4715f91080a245&v=4",
                  },
                },
              ] as Payment[]
            }
          />
        </div>
      </div>
    </RootLayout>
  );
}

export const Route = createFileRoute("/external-area/calculator")({
  component: ExternalAreaCalculatorPage,
  loader() {
    document.title = "Calculadora - COPENFL";
  },
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({ to: "/auth/signin" });
    }
  },
});
