import RootLayout from "@/layouts/root";
import { createFileRoute, redirect } from "@tanstack/react-router";

function NewsPage() {
  return (
    <RootLayout>
      <div>Hello /news/!</div>
    </RootLayout>
  );
}

export const Route = createFileRoute("/news/")({
  component: NewsPage,
  loader() {
    document.title = "Notícias - COPENFL";
  },
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({ to: "/auth/signin" });
    }
  },
});
