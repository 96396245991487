import { ButtonSubmenu } from "./button-submenu";
import { ToggleSubmenuProps } from "./types";

export function ToggleSubmenu(props: ToggleSubmenuProps) {
  const { isOpen, toggleOpen, current } = props;

  return (
    <>
      <ButtonSubmenu
        current={current}
        isOpen={isOpen}
        onClick={() => toggleOpen(!isOpen)}
        Icon={props.Icon}
        text={props.text}
        counter={props.counter}
        haveChildren
      />

      {isOpen && <ul className="ml-12 space-y-1.5 mb-2">{props.children}</ul>}
    </>
  );
}
