import * as React from "react";

import { Slot } from "@radix-ui/react-slot";
import { cn } from "@/lib/utils";
import { LoaderCircle } from "lucide-react";
import { buttonVariants } from "@/constants/button-variants";
import { VariantProps } from "class-variance-authority";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, loading, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={loading || props.disabled}
        {...props}
      >
        {loading && (
          <LoaderCircle className="animate-spin h-5 w-5 mr-3" size={20} />
        )}
        {props.children}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button };