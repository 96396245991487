import RootLayout from "@/layouts/root";
import { createFileRoute, redirect } from "@tanstack/react-router";

function EmployeesPage() {
  return (
    <RootLayout>
      <div>Hello /employees/!</div>
    </RootLayout>
  );
}

export const Route = createFileRoute("/employees/")({
  component: EmployeesPage,
  loader() {
    document.title = "Funcionários - COPENFL";
  },
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({ to: "/auth/signin" });
    }
  },
});
