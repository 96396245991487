import { ChangeEvent } from "react";
import { toast } from "sonner";

export function fileToBase64(
  fileImg: Blob,
  success: (result: string | ArrayBuffer) => void = () => {}
) {
  if (fileImg) {
    var reader = new FileReader();
    reader.onload = function (e) {
      if (e.target && e.target.result) success(e.target.result);
    };
    reader.readAsDataURL(fileImg);
  }
}

export function onFileChange(
  e: ChangeEvent<HTMLInputElement>,
  setResult: (base64: string | ArrayBuffer, file: File) => void
) {
  const element = e.target;

  if (element.files) {
    const fileInput = element.files[0];

    if (!fileInput) return;

    let file_ext = fileInput.name;
    file_ext = file_ext.split(".")[file_ext.split(".").length - 1];
    file_ext = file_ext.toUpperCase().trim();
    if (file_ext === "PNG" || file_ext === "JPG" || file_ext === "JPEG") {
      fileToBase64(fileInput, (base64) => {
        setResult(base64, fileInput);
      });
    } else {
      toast.error("Extensão da imagem não suportada");
    }
  }
}
