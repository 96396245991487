import { cn } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { ItemProps } from "./types";

type Props = Olmit<ItemProps, "Icon" | "children" | "counter">;

export function SubItem(props: Props) {
  return (
    <li>
      <Link
        to={props.href}
        className={cn(
          "flex items-center group relative rounded-xl text-zinc-800 hover:text-primary-design hover:font-bold duration-300 ease-in-out text-sm",
          props.current && "text-primary-design font-bold"
        )}
      >
        {props.title}
      </Link>
    </li>
  );
}
