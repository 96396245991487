import React from "react";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { z } from "zod";
import { Profile } from "iconsax-react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";

const FormSchema = z.object({
  user: z.string({ message: "Campo obrigatório" }),
});

function RecoverPage() {
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {},
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      if (!data.user) {
        form.setError("user", {
          type: "manual",
          message: "Campo obrigatório",
        });

        return;
      }

      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify(data);
      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch("/api/v1/auth/recover", requestOptions);

      if (res.status === 200) {
        navigate({
          to: "/auth/recover/validate/$user",
          params: { user: data.user },
        });
      } else {
        toast.error("Erro ao enviar código de verificação");
      }
    } catch (error) {
      // @ts-expect-error: 'error' is of type 'unknown'.
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center p-24 bg-zinc-50">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Card className="shadow-none border-none mt-6 max-w-[443px] px-6 py-8">
            <CardHeader className="text-center mb-2 pt-4">
              <div className="flex justify-center items-center space-x-2 mb-5">
                <img
                  src="/copenfl.png"
                  alt="COPENFL"
                  className="h-14 object-cover"
                />
                <h1 className="russo-one text-3xl">COPENFL</h1>
              </div>
              <CardTitle className="text-xl">Recuperar palavra-passe</CardTitle>
              <CardDescription>
                Insira o número que utilizaste para se cadastrar
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-8">
              <FormField
                control={form.control}
                name="user"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-base text-gray-900">
                      Telefone ou E-mail
                    </FormLabel>
                    <FormControl>
                      <Input
                        icon={<Profile size="24" color="#a1a1aa" />}
                        placeholder="Insira o seu telefone ou e-mail"
                        className="py-6"
                        type="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
            <CardFooter className="flex flex-col items-center justify-center pt-3 pb-10">
              <Button
                type="submit"
                className="py-6 w-full rounded-lg"
                loading={loading}
              >
                Continuar
              </Button>
            </CardFooter>
          </Card>
        </form>
      </Form>
    </div>
  );
}

export const Route = createFileRoute("/auth/recover/")({
  component: RecoverPage,
  loader() {
    document.title = "Recuperar palavra-passe - COPENFL";
  },
  beforeLoad: async ({ context }) => {
    if (context.auth.isAuthenticated) {
      const response = await context.auth.syncUser();
      if (response) throw redirect({ to: "/" });
    }
  },
});
