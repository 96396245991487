import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { DialogClose } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { buttonVariants } from "@/constants/button-variants";
import { onFileChange } from "@/lib/file";
import { cn } from "@/lib/utils";
import { CalendarIcon, Image, Upload } from "lucide-react";
import moment from "moment";
import React, { ChangeEvent } from "react";

type Props = {
  onSubmit?: () => void;
  onCancel?: () => void;
};

export function CreateLicenseInformation(props: Props) {
  const [date, setDate] = React.useState<Date>();
  const [blob, setBlob] = React.useState<string>();
  const [file, setFile] = React.useState<File | null>(null);

  const onCoverChange = (e: ChangeEvent<HTMLInputElement>) => {
    onFileChange(e, (result, file) => {
      setBlob(result as string);
      setFile(file);
    });
  };

  return (
    <Card>
      <CardContent className="grid grid-cols-4 gap-4 px-6 mt-6">
        <div className="col-span-4 flex flex-col justify-center items-center">
          <h3>Adicionar foto</h3>

          <div className="flex justify-center items-center max-w-[528px] w-full space-x-6 mb-4">
            <input
              className="hidden"
              type="file"
              accept="image/*"
              id="image-profile"
              onChange={onCoverChange}
            />
            <label
              htmlFor="image-profile"
              className="max-w-40 max-h-40 w-full h-full aspect-square bg-gray-200 object-cover rounded-full relative cursor-pointer"
            >
              {blob ? (
                <img
                  src={blob}
                  alt="foto-licensa"
                  className="w-40 aspect-square h-40 object-cover rounded-full"
                />
              ) : (
                <div className="justify-center items-center flex w-full h-full">
                  <Image className="w-16 h-16 text-gray-400" />
                </div>
              )}
            </label>

            <div className="space-y-5">
              <p className="text-sm text-gray-500">
                Carregue aqui a sua foto.{" "}
                <span className="text-gray-900">Orientações importantes:</span>
                800x800 pixels or 1:1 Ratio. Formato suportado:{" "}
                <span className="text-gray-900">.jpg, .jpeg, ou .png</span>
              </p>

              <label
                className={cn(
                  buttonVariants({
                    variant: "outline-app",
                    className: "py-6 px-4 cursor-pointer",
                  })
                )}
                htmlFor="image-profile"
              >
                <div className="flex justify-center items-center space-x-3 px-4">
                  <span>Carregar imagem</span>

                  <Upload className="h-4 w-4" />
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className="space-y-1 col-span-4">
          <Label>Name completo</Label>
          <Input placeholder="Ecreva o seu nome aqui..." />
        </div>
        <div className="space-y-1 col-span-2">
          <Label>Nome do pai</Label>
          <Input placeholder="Escreva o nome do pai..." />
        </div>
        <div className="space-y-1 col-span-2">
          <Label>Nome da Mãe</Label>
          <Input placeholder="Escreva o nome da mãe..." />
        </div>

        <div className="space-y-1">
          <Label>Estado cívil</Label>
          <Select>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Selecione o estado..." />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Estado cívil</SelectLabel>
                <SelectItem value="single">Solteiro(a)</SelectItem>
                <SelectItem value="married">Casado(a)</SelectItem>
                <SelectItem value="widowed">Divorciado(a)</SelectItem>
                <SelectItem value="grapes">Viúvo(a)</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-1">
          <Label>Genêro</Label>
          <Select>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Selecione o genêro..." />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Genêro</SelectLabel>
                <SelectItem value="1">Maculino</SelectItem>
                <SelectItem value="2">Feminino</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-1 col-span-2">
          <Label>Data de nascimento</Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !date && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {date ? (
                  moment(date).format("DD [de] MMMM [de] YYYY")
                ) : (
                  <span>30 de Outubro de 1995</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={setDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="space-y-1 col-span-2">
          <Label>Naturalidade</Label>
          <Input placeholder="Escreva a naturalidade..." />
        </div>

        <div className="space-y-1 col-span-2">
          <Label>Nacionalidade</Label>
          <Input placeholder="Escreva a nacionalidade..." />
        </div>

        <div className="space-y-1 col-span-2">
          <Label>País</Label>
          <Select>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Selecione o país..." />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>País</SelectLabel>
                <SelectItem value="1">Angola</SelectItem>
                <SelectItem value="2">Brazil</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-1">
          <Label>Comuna/Distrito</Label>
          <Input placeholder="Escreva a comuna ou distrito..." />
        </div>

        <div className="space-y-1">
          <Label>Munícipio</Label>
          <Input placeholder="Escreva o munícipio..." />
        </div>

        <div className="space-y-1 col-span-2">
          <Label>B.I/Passaporte</Label>
          <Input placeholder="ex: 00123456HO7891" />
        </div>

        <div className="space-y-1">
          <Label>Data de emissão</Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !date && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {date ? (
                  moment(date).format("DD [de] MMMM [de] YYYY")
                ) : (
                  <span>01 de Janeiro de 2024</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={setDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="space-y-1">
          <Label>Data de caducidade</Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !date && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {date ? (
                  moment(date).format("DD [de] MMMM [de] YYYY")
                ) : (
                  <span>01 de Janeiro de 2034</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={setDate}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="space-y-1 col-span-4">
          <Label>Passado por</Label>
          <Input placeholder="Escreva o local de emissão..." />
        </div>
      </CardContent>
      <CardFooter className="justify-between">
        <DialogClose asChild>
          <Button className="py-6 rounded-lg" variant={"outline"}>
            Cancelar
          </Button>
        </DialogClose>
        <Button className="py-6 rounded-lg" onClick={props.onSubmit}>
          Guardar e seguinte
        </Button>
      </CardFooter>
    </Card>
  );
}
