import {
  Category,
  Bag,
  TwoUsers,
  Folder,
  Activity,
  Chart,
  People,
} from "react-iconly";

import { useLocation } from "@tanstack/react-router";
import { GraduationCap, Newspaper } from "lucide-react";
import { Devices, Moneys } from "iconsax-react";
import { SubItem } from "./sub-item";
import { Item } from "./item";

export function SideBar() {
  const { pathname } = useLocation();

  return (
    <aside className="w-[17.375rem] bg-white rounded-sm px-3.5 py-4">
      <ul className="flex flex-col gap-1.5">
        <Item
          Icon={Category}
          current={pathname === "/"}
          href="/"
          title="Dashboard"
        />
        <Item
          current={pathname.startsWith("/external-reception")}
          Icon={Bag}
          href="/external-reception/license"
          title="Recepção Externa"
          counter={3}
        >
          <SubItem
            href="/external-reception/add-process"
            title="Adicionar processo"
            current={pathname === "/external-reception/add-process"}
          />
          <SubItem
            href="/external-reception/license"
            current={pathname === "/external-reception/license"}
            title="Licenças"
          />
        </Item>
        <Item
          current={pathname.startsWith("/external-area")}
          Icon={TwoUsers}
          href="/external-area"
          title="Área Externa"
        >
          <SubItem
            href="/external-area/license"
            current={pathname === "/external-area/license"}
            title="Licenças"
          />
          <SubItem
            href="/external-area/calculator"
            current={pathname === "/external-area/calculator"}
            title="Calculadora"
          />
        </Item>
        <Item
          current={pathname.startsWith("/internal-area")}
          Icon={Folder}
          href="/internal-area"
          title="Área Interna"
        />

        <Item
          current={pathname.startsWith("/analysis")}
          Icon={Activity}
          href="/analysis"
          title="Análise"
        />

        <Item
          current={pathname.startsWith("/schools")}
          // @ts-ignore
          Icon={GraduationCap}
          href="/schools"
          title="Escolas"
        />

        <Item
          current={pathname.startsWith("/computing")}
          // @ts-ignore
          Icon={Devices}
          href="/computing"
          title="Informática"
        />

        <Item
          current={pathname.startsWith("/news")}
          // @ts-ignore
          Icon={Newspaper}
          href="/news"
          title="Notícias"
        />

        <Item
          current={pathname.startsWith("/employees")}
          Icon={TwoUsers}
          href="/employees"
          title="Funcionários"
        />

        <Item
          current={pathname.startsWith("/data-management")}
          Icon={Chart}
          href="/data-management"
          title="Gestão de Dados e Análises"
        />

        <Item
          current={pathname.startsWith("/finance")}
          // @ts-ignore
          Icon={Moneys}
          href="/finance"
          title="Finanças"
        />

        <Item
          current={pathname.startsWith("/administrator")}
          Icon={People}
          href="/administrator"
          title="Administrador"
        />
      </ul>
    </aside>
  );
}
