import React from "react";

import { Header } from "@/components/header";
import { SideBar } from "@/components/sidebar";

export default function RootLayout(props: { children?: React.ReactNode }) {
  const { children } = props;

  return (
    <main className="bg-zinc-50 min-h-screen">
      <div className="max-w-7xl mx-auto py-6 px-4">
        <Header />

        <main className="flex justify-between items-start">
          <SideBar />

          <div className="w-[calc(100%_-_17.375rem)] pl-4">{children}</div>
        </main>
      </div>
    </main>
  );
}
