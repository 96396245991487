import React from "react";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  createFileRoute,
  redirect,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";

const FormSchema = z.object({
  token: z.string({ message: "Campo obrigatório" }),
});

function RecoverValidatePage() {
  const { user } = useParams({ strict: false });
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {},
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      if (!data.token) {
        form.setError("token", {
          type: "manual",
          message: "Campo obrigatório",
        });

        return;
      }

      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify(data);
      const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch("/api/v1/auth/recover/validate", requestOptions);

      if (res.status === 200) {
        navigate({
          to: "/auth/recover/finish/$token",
          params: { token: data.token },
        });
      } else {
        toast.error("Erro ao verificar o código");
      }
    } catch (error) {
      // @ts-expect-error: 'error' is of type 'unknown'.
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center p-24 bg-zinc-50">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Card className="shadow-none border-none mt-6 max-w-[448px] px-6 py-8">
            <CardHeader className="text-center mb-2 pt-4">
              <div className="flex justify-center items-center space-x-2 mb-5">
                <img
                  src="/copenfl.png"
                  alt="COPENFL"
                  className="h-14 object-cover"
                />
                <h1 className="russo-one text-3xl">COPENFL</h1>
              </div>
              <CardTitle className="text-xl">
                Verificar número de telefone
              </CardTitle>
              <CardDescription>
                Um texto com uma Palavra-Passe Única (OTP) foi enviado para:{" "}
                {user}
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-8">
              <FormField
                control={form.control}
                name="token"
                render={({ field }) => (
                  <FormItem>
                    <FormControl className="flex justify-center items-center w-full">
                      <InputOTP
                        maxLength={6}
                        className="flex justify-center items-center w-full"
                        {...field}
                      >
                        <InputOTPGroup className="flex justify-center items-center gap-4 w-full">
                          <InputOTPSlot
                            className="bg-[#F8F9FB] border-none rounded-md p-6 font-medium text-2xl"
                            index={0}
                          />
                          <InputOTPSlot
                            className="bg-[#F8F9FB] border-none rounded-md p-6 font-medium text-2xl"
                            index={1}
                          />
                          <InputOTPSlot
                            className="bg-[#F8F9FB] border-none rounded-md p-6 font-medium text-2xl"
                            index={2}
                          />

                          <InputOTPSlot
                            className="bg-[#F8F9FB] border-none rounded-md p-6 font-medium text-2xl"
                            index={3}
                          />
                          <InputOTPSlot
                            className="bg-[#F8F9FB] border-none rounded-md p-6 font-medium text-2xl"
                            index={4}
                          />
                          <InputOTPSlot
                            className="bg-[#F8F9FB] border-none rounded-md p-6 font-medium text-2xl"
                            index={5}
                          />
                        </InputOTPGroup>
                      </InputOTP>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
            <CardFooter className="flex flex-col items-center justify-center pt-3 pb-10">
              <Button
                type="submit"
                className="py-6 w-full rounded-lg"
                loading={loading}
              >
                Confirmar
              </Button>
            </CardFooter>
          </Card>
        </form>
      </Form>
    </div>
  );
}

export const Route = createFileRoute("/auth/recover/validate/$user")({
  component: RecoverValidatePage,
  loader({ params }) {
    document.title = "Verificar número de telefone - COPENFL";
    return { user: params.user };
  },
  beforeLoad: async ({ context }) => {
    if (context.auth.isAuthenticated) {
      const response = await context.auth.syncUser();
      if (response) throw redirect({ to: "/" });
    }
  },
});
